.task-list-view {
  @apply flex flex-col h-full;
}

.task-list-view__task-list {
  @apply mt-6 h-full;
}

.task-list-view__header--text {
  @apply mt-2 text-lg text-[color:var(--text-color)] font-medium;
}

.task-list-view__header--description {
  @apply mt-1 mx-auto text-[color:var(--text-brightest-color)] text-sm;
  max-width: 32rem;

  @media (min-width: 640px) {
    max-width: 18rem;
  }

  @media (min-width: 700px) {
    max-width: 20rem;
  }

  @media (min-width: 850px) {
    max-width: 32rem;
  }

  @media (min-width: 1110px) {
    max-width: 36rem;
  }
}

table.task-list-view__table {
  @apply min-w-full divide-y divide-gray-300;

  thead {
    // https://lakur.tech/2022/03/31/tailwind-scss-resolve-url-loader-error-processing-css-invalid-mapping/
    background-color: var(--task-list-table-header-background-color);

    th {
      @apply text-left text-sm font-semibold text-[color:var(--text-color)] py-3.5 px-3;
      @apply last:pl-3 last:pr-4 last:sm:pr-6;
      @apply first:pl-4 first:pr-3 first:sm:pl-6;
    }
  }

  tbody {
    @apply divide-[color:var(--task-list-table-divider-color)] bg-[color:var(--task-list-table-body-background-color)] divide-y;

    td {
      @apply py-4 px-3 text-sm;
      @apply last:pl-3 last:pr-4 last:sm:pr-6;
      @apply first:pl-4 first:pr-3 first:sm:pl-6;
    }
  }
}

.app-layout.app-layout--task-list-view {
  background-color: var(--task-list-background-color);
}
