.startable-group {
  @apply mt-10 first:mt-0;
}

// https://lakur.tech/2022/03/31/tailwind-scss-resolve-url-loader-error-processing-css-invalid-mapping/
.startable-group__title {
  @apply font-semibold uppercase tracking-wide;
  @apply text-[color:var(--startable-view-group-title-color)] text-xs;
}

.startable-group__startables {
  @apply mt-4 grid gap-6;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
}
