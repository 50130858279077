.startable__title,
.startable__body,
.startable__start-event {
  @apply text-sm font-medium truncate;
}

//https://lakur.tech/2022/03/31/tailwind-scss-resolve-url-loader-error-processing-css-invalid-mapping/
// Add block again after [] to avoid build error
.startable__title {
  @apply text-[color:var(--text-color)] block;
}

.startable__body,
.startable__start-event {
  @apply text-[color:var(--text-brightest-color)] block;
}

.process-model__start-button,
.start-dialog__start-button {
  @apply w-full flex items-center justify-between rounded-full border shadow-sm text-left focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply bg-[color:var(--button-background-color)] border-[color:var(--button-border-color)] shadow-[color:var(--shadow-color)] hover:bg-[color:var(--button-background-hover-color)] focus:ring-offset-[color:var(--background-color)] focus:ring-[color:var(--focus-color)] p-2;
}
