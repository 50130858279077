.correlation-tracker-view__infopoint {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

table.correlation-tracker-view__table {
  @apply min-w-full divide-y divide-gray-300;

  thead {
    background-color: var(--correlation-tracker-table-header-background-color);

    th {
      @apply text-left text-sm font-semibold text-[color:var(--text-color)] py-3.5 px-3;
      @apply last:pl-3 last:pr-4 last:sm:pr-6;
      @apply first:pl-4 first:pr-3 first:sm:pl-6;
    }
  }

  tbody {
    @apply divide-[color:var(--correlation-tracker-table-divider-color)] bg-[color:var(--correlation-tracker-table-body-background-color)] divide-y;

    td {
      @apply py-4 px-3 text-sm;
      @apply last:pl-3 last:pr-4 last:sm:pr-6;
      @apply first:pl-4 first:pr-3 first:sm:pl-6;
    }
  }
}

.app-layout.app-layout--correlation-tracker-view {
  background-color: var(--correlation-tracker-background-color);
}
