.user-settings-view__version {
  @apply pl-1 text-xs;
  color: var(--text-brightest-color);
}

// https://lakur.tech/2022/03/31/tailwind-scss-resolve-url-loader-error-processing-css-invalid-mapping/
.user-settings-view__language-select {
  @apply space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10;
}

.user-settings-view__language-select-option {
  @apply flex items-center;

  .user-settings-view__language-select-option-input {
    @apply border-[color:var(--border-color)] text-[color:var(--focus-color)] focus:ring-[color:var(--focus-color)] h-4 w-4;
  }

  .user-settings-view__language-select-option-label {
    @apply text-[color:var(--text-bright-color)] ml-3 block text-sm font-medium;
  }
}

.app-layout.app-layout--user-settings-view {
  background-color: var(--user-settings-view-background-color);
}
