@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  svg {
    @apply inline;
  }
}

@import 'theme/app-base.scss';
@import 'theme/default-theme.css';
