.task-view {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.task-view__content {
  display: flex;
  height: 100%;
  flex-direction: column;

  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (max-height: 667px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 639px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.app-layout.app-layout--task-view .app-layout__content {
  @apply pt-0;

  @media (max-width: 639px) {
    @apply px-0;
  }
}

.task-view-termination-modal {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;

  .task-view-termination-modal__overlay {
    @apply fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity;
  }

  .task-view-termination-modal__content {
    @apply relative inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;

    .task-view-termination-modal__content-body {
      @apply bg-[color:var(--task-view-termination-modal-body-background-color)] px-4 pt-5 pb-4 sm:p-6 sm:pb-4;
    }

    .task-view-termination-modal__content-footer {
      @apply bg-[color:var(--task-view-termination-modal-footer-background-color)] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse;

      .task-view-termination-modal__content-footer__button {
        @apply w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[color:var(--task-view-termination-modal-footer-background-color)] sm:ml-3 sm:w-auto sm:text-sm;
      }

      .task-view-termination-modal__content-footer__button--confirm {
        @apply border-transparent bg-red-600 text-white hover:bg-red-700  focus:ring-red-500;
      }

      .task-view-termination-modal__content-footer__button--cancel {
        @apply mt-3 border-[color:var(--button-border-color)] bg-[color:var(--button-background-color)] text-[color:var(--button-text-color)] hover:bg-[color:var(--button-background-hover-color)] focus:ring-[color:var(--focus-color)] sm:mt-0;
      }
    }
  }
}

.app-layout.app-layout--task-view {
  background-color: var(--task-view-background-color);
}
